import React, { useState, useMemo } from 'react';
import {
  Camera,
  Check,
  Loader2,
  LogIn,
  LogOut,
  MapPin,
  User,
  X,
} from 'lucide-react';
import { useSelector } from 'react-redux';
import { getMainDateConfiguration } from 'utils/utils';
import {
  getDistanceInMeters,
  isFartherThan,
} from 'components-kit/Organisms/AttendanceModal/Models/AttendanceData';
import { DayPicker } from 'react-day-picker';
import { es } from 'date-fns/locale';
import { format } from 'date-fns';
import {
  AttendanceData,
  CheckProcessSchema,
} from 'components/Organisms/TitaskAttendanceModal/Models/AttendanceData';
import PhotoDetailModal from 'components/Organisms/TitaskAttendanceModal/PhotoDetailModal';
import MapGpsDetailModal from 'components/Organisms/TitaskAttendanceModal/MapGpsDetailModal';
import { UpdateCheckStatesManually } from 'scenes/Tasks/Actions/components/EditTitaskAttendanceModal';

interface CheckCardProps {
  checkData: CheckProcessSchema | null;
  type: 'in' | 'out';
  submit: (sendingParams: UpdateCheckStatesManually) => void;
  attendanceData: AttendanceData;
}

const DISTANCE_THRESHOLD_IN_METERS = 100;

function CheckCard({
  checkData,
  type,
  attendanceData,
  submit,
}: CheckCardProps) {
  const [loading, setLoading] = useState(false);
  const userAuth = useSelector((state: any) =>
    state?.auth ? state?.auth : []
  );
  const mainDateFormat = getMainDateConfiguration(userAuth);
  const [isGpsModalOpen, setIsGpsModalOpen] = useState(false);
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const isOutsideRadius = useMemo(() => {
    if (!attendanceData.store.location || !checkData?.location) {
      return false;
    }
    return isFartherThan(
      attendanceData.store.location,
      checkData.location,
      DISTANCE_THRESHOLD_IN_METERS
    );
  }, [attendanceData.store.location, checkData?.location]);

  const disabledGpsButton = useMemo(() => {
    return !checkData || !checkData.checkedBy.isOwner || !checkData.location;
  }, [checkData]);

  const disabledPhotoButton = useMemo(() => {
    return !checkData || !checkData.checkedBy.isOwner || !checkData.photoUrl;
  }, [checkData]);

  const handleEditDate = () => {
    if (!(checkData || (type === 'out' && !attendanceData.checkIn))) {
      setSelectedDate(null);
      setIsDatePickerModalOpen(true);
    }
  };

  const handleSaveDate = async () => {
    setLoading(true);
    await submit({
      userId: attendanceData.id.userId,
      turnId: attendanceData.id.turnId,
      checkIn: {
        edit: type === 'in',
        date: attendanceData.checkIn
          ? attendanceData.checkIn.executedDate.toISOString()
          : type === 'in'
          ? selectedDate
            ? selectedDate.toISOString()
            : null
          : null,
      },
      checkOut: {
        edit: type === 'out',
        date: attendanceData.checkOut
          ? attendanceData.checkOut.executedDate.toISOString()
          : type === 'out'
          ? selectedDate
            ? selectedDate.toISOString()
            : null
          : null,
      },
    });
    setLoading(false);
    setIsDatePickerModalOpen(false);
  };

  return (
    <div
      className={`attendance-timeline-entry ${
        type === 'out' && !attendanceData.checkIn ? 'attendance-disabled' : ''
      }`}
    >
      <div className="attendance-time-marker">
        <div className="attendance-marker">
          {type === 'in' ? <LogIn size={20} /> : <LogOut size={20} />}
        </div>
        <div className="attendance-time">
          <span className="attendance-label">
            Check {type === 'in' ? 'In' : 'Out'}
          </span>
          {checkData ? (
            <span className="attendance-timestamp">
              {checkData.executedDate.format(`${mainDateFormat} h:mmA`)}
            </span>
          ) : (
            <button
              className="attendance-edit-time"
              onClick={handleEditDate}
              disabled={loading || (type === 'out' && !attendanceData.checkIn)}
            >
              Marcar hora
            </button>
          )}
        </div>
      </div>
      {checkData && (
        <div className="attendance-entry-details">
          <div className="attendance-marked-by">
            <User size={14} />
            <span>{`${!checkData.checkedBy.isOwner ? 'Supervisor: ' : ''}${
              checkData.checkedBy.fullName
            }`}</span>
          </div>
          <div className="attendance-action-buttons">
            <button
              className={`attendance-action-btn ${
                isOutsideRadius ? 'warning' : ''
              }`}
              onClick={() => setIsGpsModalOpen(true)}
              disabled={disabledGpsButton}
            >
              <MapPin size={18} />
              {!disabledGpsButton && (
                <div className="attendance-tooltip">
                  {isOutsideRadius
                    ? `Marcación realizada a más de ${DISTANCE_THRESHOLD_IN_METERS}m de la tienda`
                    : 'Ver ubicación de marcaje'}
                </div>
              )}
            </button>
            <button
              className="attendance-action-btn"
              onClick={() => setIsPhotoModalOpen(true)}
              disabled={disabledPhotoButton}
            >
              <Camera size={18} />
              {!disabledPhotoButton && (
                <div className="attendance-tooltip">Ver foto</div>
              )}
            </button>
          </div>
        </div>
      )}

      {checkData && checkData.photoUrl && (
        <PhotoDetailModal
          isOpen={isPhotoModalOpen}
          handleCloseModal={() => setIsPhotoModalOpen(false)}
          photoUrl={checkData.photoUrl}
        />
      )}
      {checkData && checkData.location && (
        <MapGpsDetailModal
          isOpen={isGpsModalOpen}
          handleCloseModal={() => setIsGpsModalOpen(false)}
          storeData={
            attendanceData.store.location
              ? {
                  lat: attendanceData.store.location.latitude,
                  lng: attendanceData.store.location.longitude,
                  content: attendanceData.store.name,
                }
              : null
          }
          checkInData={
            attendanceData.checkIn && attendanceData.checkIn.location
              ? {
                  lat: attendanceData.checkIn.location.latitude,
                  lng: attendanceData.checkIn.location.longitude,
                  content: attendanceData.checkIn.executedDate.format(
                    `${mainDateFormat} h:mmA`
                  ),
                  distance:
                    getDistanceInMeters(
                      attendanceData.store.location,
                      attendanceData.checkIn.location
                    ) || undefined,
                }
              : null
          }
          checkOutData={
            attendanceData.checkOut && attendanceData.checkOut.location
              ? {
                  lat: attendanceData.checkOut.location.latitude,
                  lng: attendanceData.checkOut.location.longitude,
                  content: attendanceData.checkOut.executedDate.format(
                    `${mainDateFormat} h:mmA`
                  ),
                  distance:
                    getDistanceInMeters(
                      attendanceData.store.location,
                      attendanceData.checkOut.location
                    ) || undefined,
                }
              : null
          }
          currentType={type}
        />
      )}
      {!checkData && isDatePickerModalOpen && (
        <div className="attendance-overlay">
          <div className="attendance-date-picker-modal">
            <button
              className="attendance-close-button"
              onClick={() => setIsDatePickerModalOpen(false)}
            >
              <X size={20} />
            </button>
            <h3>Seleccionar fecha y hora</h3>
            <DayPicker
              mode="single"
              selected={selectedDate || undefined}
              onSelect={(date) => date && setSelectedDate(date)}
              locale={es}
            />
            <div className="attendance-time-picker">
              <input
                type="time"
                value={selectedDate ? format(selectedDate, 'HH:mm') : ''}
                disabled={!selectedDate || loading}
                onChange={(e) => {
                  if (selectedDate) {
                    const [hours, minutes] = e.target.value.split(':');
                    const newDate = new Date(selectedDate);
                    newDate.setHours(parseInt(hours), parseInt(minutes));
                    setSelectedDate(newDate);
                  }
                }}
              />
            </div>
            <button
              className="attendance-save-button"
              onClick={handleSaveDate}
              disabled={!selectedDate || loading}
            >
              {loading ? (
                <Loader2 size={16} className="attendance-spinner" />
              ) : (
                <Check size={16} />
              )}
              Guardar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CheckCard;

import React, { useState, useEffect } from 'react';
import Select from 'components/Atoms/Select/Select';
import { useNavigate } from 'react-router-dom';
import TextInput from 'components/Atoms/TextInput/TextInput';
import { OnChangeValueType } from 'utils/utils';
import useForm from 'hooks/useForm';
import SwitchOption from 'components/Atoms/Switch/Switch';
import TasksService from '../services/TasksService';
import { toast } from 'react-toastify';
import GenericSkeleton from 'components/Atoms/GenericSkeleton/GenericSkeleton';
interface PersonalDataFormInterface {
  onSubmit: (e: any) => void;
  taskSelected?: any;
  formId: string;
  permissions: any;
  setParentLoading?: (value: boolean) => void;
}

const INITIAL_EMPTY_FORM_VALUES: any = {
  client: {
    text: '',
    value: '',
  },
  certification: {
    text: '',
    value: '',
  },
  vertical: {
    text: '',
    value: '',
  },
  kam: {
    text: '',
    value: '',
  },
  control_tower: {
    text: '',
    value: '',
  },
  task_name: '',
  checkin_grace_period: '',
  checkout_grace_period: '',
};

const TaskDataForm = ({
  onSubmit,
  taskSelected,
  formId,
  permissions,
  setParentLoading,
}: PersonalDataFormInterface) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(INITIAL_EMPTY_FORM_VALUES);
  const checkInGracePeriod = taskSelected
    ? !!taskSelected.checkinGracePeriodRequired
    : false;

  const checkOutGracePeriod = taskSelected
    ? !!taskSelected.checkoutGracePeriodRequired
    : false;
  const { form, onChange, validFields } = useForm(
    taskSelected
      ? {
          client: {
            text: taskSelected.companyName ?? taskSelected.certificationName,
            value: taskSelected.companyId,
          },
          certification: {
            text: taskSelected.certificationName,
            value: taskSelected.certificationId,
          },
          vertical: {
            text: taskSelected.verticalName,
            value: taskSelected.verticalId,
          },
          kam: {
            text: `${taskSelected.kamUserName} ${taskSelected.kamUserLastname}`,
            value: taskSelected.kamUserId,
          },
          control_tower: {
            text: `${taskSelected.controlTowerUserName} ${taskSelected.controlTowerUserLastname}`,
            value: taskSelected.controlTowerUserId,
          },
          task_name: taskSelected.name,
          checkin_grace_period: checkInGracePeriod
            ? `${parseInt(taskSelected?.checkinGracePeriod) || 0}`
            : '',
          checkout_grace_period: checkOutGracePeriod
            ? `${parseInt(taskSelected?.checkoutGracePeriod) || 0}`
            : '',
        }
      : INITIAL_EMPTY_FORM_VALUES
  );
  const disabledFields = false;
  const history = useNavigate();
  const [data, setData] = useState({} as any);
  const [dataCliente, setDataCliente] = useState([]);
  const [dataVerticales, setDataVerticales] = useState([]);
  const [dataCertificaciones, setDataCertificaciones] = useState([]);
  const [dataKam, setDataKam] = useState([]);
  const [dataControlTower, setDataControlTower] = useState([]);
  const [switches, setSwitches] = useState({
    checkingQR: false,
    checkoutQR: false,
    geoCheckin: false,
    geoCheckout: false,
    signed_contract: false,
    checkInGracePeriod: false,
    checkOutGracePeriod: false,
  });

  const [loadingCertifications, setLoadingCertifications] = useState(true);

  const switchOnChange = (switchType: string, checked: boolean) => {
    setSwitches({
      ...switches,
      [switchType]: checked,
    });
  };

  const onSubmitForm = async (e: any) => {
    e.preventDefault();

    let newErrors: any = validFields();
    newErrors = Object.fromEntries(
      Object.entries(newErrors).filter(
        ([key]) =>
          !['checkin_grace_period', 'checkout_grace_period'].includes(key)
      )
    );

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const sendData = {
        name: form.task_name,
        companyId: form.client.value,
        verticalId: form.vertical.value,
        certificationId: form.certification.value,
        kamUserId: form.kam.value || null,
        controlTowerUserId: form.control_tower.value || null,
        checkinQrRequired: switches.checkingQR,
        checkoutQrRequired: switches.checkoutQR,
        signContractRequired: switches.signed_contract,
        geolocationCheckinRequired: switches.geoCheckin,
        geolocationCheckoutRequired: switches.geoCheckout,
        riskLevel: 0,
        checkInGracePeriod:
          parseInt(`${form.checkin_grace_period}`) || undefined,
        checkOutGracePeriod:
          parseInt(`${form.checkout_grace_period}`) || undefined,
      };
      if (setParentLoading) {
        setParentLoading(true);
      }
      let isSuccessfully = false;
      if (taskSelected) {
        const response = await TasksService.updateTaskById(
          taskSelected.id,
          sendData
        );
        if (response.statusCode === 200) {
          isSuccessfully = true;
        }
      } else {
        const responseSaveTask = await TasksService.saveTask(sendData);
        if (responseSaveTask.statusCode === 200) {
          isSuccessfully = true;
        }
      }

      if (setParentLoading) {
        setParentLoading(false);
      }

      if (isSuccessfully) {
        const message = taskSelected
          ? 'Se actualizó la tarea satisfactoriamente.'
          : 'Se creó la tarea satisfactoriamente.';
        toast.success(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        onSubmit(form);
        if (!taskSelected) {
          history('/tasks');
        }
      } /* else {
        toast.warn(
          '¡UPS! No se pudo actualizar esta tarea, inténtalo nuevamente por favor',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
      }*/
    }
  };

  const loadDataSelects = async () => {
    if (setParentLoading) {
      setParentLoading(true);
    }
    setLoading(true);

    const [
      responseCliente,
      responseVerticales,
      responseKam,
      responseControlTower,
    ] = await Promise.all([
      TasksService.getDataClientesTitask(),
      TasksService.getDataVerticales(),
      TasksService.getDataKam(),
      TasksService.getDataControlTower(),
    ]);

    if (responseCliente.data.length > 0) {
      setDataCliente(responseCliente.data);
    }
    if (responseVerticales.data.length > 0) {
      setDataVerticales(responseVerticales.data);
    }
    if (responseKam.data.length > 0) {
      setDataKam(responseKam.data);
    }
    if (responseControlTower.data.length > 0) {
      setDataControlTower(responseControlTower.data);
    }

    let valorCliente = null;
    if (responseCliente.data.length > 0) {
      valorCliente = responseCliente.data.find(
        (valor: any) => valor.value === taskSelected?.companyId
      );
    }
    let valorVertical = null;
    if (responseVerticales.data.length > 0) {
      valorVertical = responseVerticales.data.find(
        (valor: any) => valor.value === taskSelected?.verticalId
      );
    }
    let ValorKam = null;
    if (responseKam.data.length > 0) {
      ValorKam = responseKam.data.find(
        (valor: any) => valor.value === taskSelected?.kamUserId
      );
    }

    let valorTower = null;
    if (responseControlTower.data.length > 0) {
      valorTower = responseControlTower.data.find(
        (valor: any) => valor.value === taskSelected?.controlTowerUserId
      );
    }
    loadDataById(taskSelected, {
      valorCliente,
      valorVertical,
      ValorKam,
      valorTower,
    });
    setLoading(false);
    if (setParentLoading) {
      setParentLoading(false);
    }
  };

  const loadDataCertificaciones = async (value: string) => {
    let valorCertificacion = null;
    // if (setParentLoading) {
    //   setParentLoading(true);
    // }
    const responseCertificaciones = await TasksService.getDataCertifications(
      value
    );

    if (responseCertificaciones.data.length > 0) {
      setDataCertificaciones(responseCertificaciones.data);
    }

    if (taskSelected && taskSelected.id) {
      if (responseCertificaciones.data.length > 0) {
        valorCertificacion = responseCertificaciones.data.find(
          (valor: any) => valor.value === taskSelected.certificationId
        );
      }
    }
    if (valorCertificacion) {
      onChange(valorCertificacion, 'certification');
      setData({
        ...data,
        certification: valorCertificacion,
      });
    }

    // if (setParentLoading) {
    //   setParentLoading(false);
    // }
    setLoadingCertifications(false);
  };

  const loadDataById = async (taskSelected: any, selectValues: any) => {
    if (selectValues.valorCliente) {
      onChange(selectValues.valorCliente, 'client');
    }
    if (selectValues.valorVertical) {
      onChange(selectValues.valorVertical, 'vertical');
    }
    if (selectValues.ValorKam) {
      onChange(selectValues.ValorKam, 'kam');
    }
    if (selectValues.valorTower) {
      onChange(selectValues.valorTower, 'control_tower');
    }

    const checkInGracePeriod = taskSelected
      ? !!taskSelected.checkinGracePeriodRequired
      : false;

    const checkOutGracePeriod = taskSelected
      ? !!taskSelected.checkoutGracePeriodRequired
      : false;

    setSwitches({
      checkingQR: taskSelected?.checkinQrRequired || false,
      checkoutQR: taskSelected?.checkoutQrRequired || false,
      geoCheckin: taskSelected?.geolocationCheckinRequired || false,
      geoCheckout: taskSelected?.geolocationCheckoutRequired || false,
      signed_contract: taskSelected?.signContractRequired || false,
      checkInGracePeriod,
      checkOutGracePeriod,
    });
    setData({
      ...data,
      task_name: taskSelected?.name,
      document_type: selectValues.valorCliente,
      vertical: selectValues.valorVertical,
      kam: selectValues.ValorKam,
      control_tower: selectValues.valorTower,
      checkin_grace_period: checkInGracePeriod
        ? `${parseInt(taskSelected?.checkinGracePeriod) || 0}`
        : '',
      checkout_grace_period: checkOutGracePeriod
        ? `${parseInt(taskSelected?.checkoutGracePeriod) || 0}`
        : '',
    });
  };

  useEffect(() => {
    onChange('', 'certification');
    setDataCertificaciones([]);

    if (form.client?.value) {
      setLoadingCertifications(true);
      loadDataCertificaciones(form.client.value);
    }
  }, [form.client]);

  useEffect(() => {
    loadDataSelects();
  }, []);

  if (loading) {
    return <GenericSkeleton />;
  }

  return (
    <div className="form__main form__main--data">
      {Object.keys(data).length > 0 && (
        <form method="POST" onSubmit={onSubmitForm} id={formId}>
          <div className="fields-group fields-group--cols-2 fields-group--rows-2">
            <div className="field-item">
              <TextInput
                className="custom__height__general__form__view__task"
                errors={errors}
                inputProps={{ required: true }}
                label={'Nombre de la tarea'}
                name="task_name"
                onChange={({ target }) => onChange(target.value, 'task_name')}
                placeholder={'Escribe el nombre de la tarea'}
                inputPropsAdornments={{
                  required: true,
                }}
                disabled={disabledFields}
                value={form.task_name}
              />
            </div>

            <div className="field-item">
              <Select
                className="select__style"
                data={dataCliente}
                errors={errors}
                label={'Cliente'}
                name="document_type"
                onChange={(_e: any, newValue?: OnChangeValueType) => {
                  if (newValue) onChange(newValue, 'client');
                  else onChange('', 'client');
                }}
                placeholder={'Selecciona un Cliente'}
                required
                disabled={disabledFields}
                value={form.client}
              />
            </div>

            <div className="field-item">
              <Select
                className="select__style"
                data={dataVerticales}
                errors={errors}
                label={'Vertical'}
                name="vertical"
                onChange={(_e: any, newValue?: OnChangeValueType) => {
                  if (newValue) onChange(newValue, 'vertical');
                  else onChange('', 'vertical');
                }}
                placeholder={'Selecciona una vertical'}
                required
                disabled={disabledFields}
                value={form.vertical}
              />
            </div>

            <div className="field-item">
              <Select
                className="select__style"
                data={dataCertificaciones}
                errors={errors}
                label={'Certificación'}
                name="certification"
                onChange={(_e: any, newValue?: OnChangeValueType) => {
                  if (newValue) onChange(newValue, 'certification');
                  else onChange('', 'certification');
                }}
                placeholder={
                  !form.client
                    ? 'Selecciona un cliente'
                    : loadingCertifications
                    ? 'Cargando...'
                    : 'Selecciona una certificación'
                }
                required
                disabled={
                  disabledFields || !form.client || loadingCertifications
                }
                value={form.certification}
              />
            </div>

            {/*<div className="field-item">*/}
            {/*  <Select*/}
            {/*    className="select__style"*/}
            {/*    data={dataKam}*/}
            {/*    errors={errors}*/}
            {/*    label={'KAM'}*/}
            {/*    name="kam"*/}
            {/*    onChange={(_e: any, newValue?: OnChangeValueType) => {*/}
            {/*      if (newValue) onChange(newValue, 'kam');*/}
            {/*      else onChange('', 'kam');*/}
            {/*    }}*/}
            {/*    placeholder={'Selecciona un KAM'}*/}
            {/*    required*/}
            {/*    disabled={disabledFields}*/}
            {/*    value={form.kam}*/}
            {/*  />*/}
            {/*</div>*/}

            {/*<div className="field-item">*/}
            {/*  <Select*/}
            {/*    className="select__style"*/}
            {/*    data={dataControlTower}*/}
            {/*    errors={errors}*/}
            {/*    label={'Torre de control'}*/}
            {/*    name="control_tower"*/}
            {/*    onChange={(_e: any, newValue?: OnChangeValueType) => {*/}
            {/*      if (newValue) onChange(newValue, 'control_tower');*/}
            {/*      else onChange('', 'control_tower');*/}
            {/*    }}*/}
            {/*    placeholder={'Selecciona una torre de control'}*/}
            {/*    required*/}
            {/*    disabled={disabledFields}*/}
            {/*    value={form.control_tower}*/}
            {/*  />*/}
            {/*</div>*/}

            <div className="field-item">
              <p className="check-title-titask">Contrato:</p>
              <SwitchOption
                checked={switches.signed_contract}
                label="Los turnos requerirán un contrato firmado"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  switchOnChange('signed_contract', event.target.checked)
                }
                withoutTooltip
              />
            </div>

            <div className="field-item" />

            <div className="field-item">
              <p className="check-title-titask">Check in:</p>

              <SwitchOption
                checked={switches.checkingQR}
                label="Requiere código QR"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  switchOnChange('checkingQR', event.target.checked)
                }
                withoutTooltip
              />

              <SwitchOption
                checked={switches.geoCheckin}
                label="Requiere geolocalización y foto"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  switchOnChange('geoCheckin', event.target.checked)
                }
                withoutTooltip
              />

              <SwitchOption
                checked={switches.checkInGracePeriod}
                label="Restringir marcado por tiempo"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  switchOnChange('checkInGracePeriod', event.target.checked)
                }
                withoutTooltip
              />

              {switches.checkInGracePeriod && (
                <TextInput
                  className="custom__height__general__form__view__task wo-form-inner-input-padding"
                  errors={errors}
                  inputProps={{ required: true }}
                  label={''}
                  name="checkin_grace_period"
                  onChange={({ target }) =>
                    onChange(target.value, 'checkin_grace_period')
                  }
                  placeholder={'Ingrese la holgura en minutos'}
                  disabled={disabledFields}
                  value={form.checkin_grace_period}
                  type="positive_integer"
                />
              )}
            </div>

            <div className="field-item">
              <p className="check-title-titask">Check out:</p>

              <SwitchOption
                checked={switches.checkoutQR}
                label="Requiere código QR"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  switchOnChange('checkoutQR', event.target.checked)
                }
                withoutTooltip
              />
              <SwitchOption
                checked={switches.geoCheckout}
                label="Requiere geolocalización y foto"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  switchOnChange('geoCheckout', event.target.checked)
                }
                withoutTooltip
              />
              <SwitchOption
                checked={switches.checkOutGracePeriod}
                label="Restringir marcado por tiempo"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  switchOnChange('checkOutGracePeriod', event.target.checked)
                }
                withoutTooltip
              />
              {switches.checkOutGracePeriod && (
                <TextInput
                  className="custom__height__general__form__view__task wo-form-inner-input-padding"
                  errors={errors}
                  inputProps={{ required: true }}
                  label={''}
                  name="checkout_grace_period"
                  onChange={({ target }) =>
                    onChange(target.value, 'checkout_grace_period')
                  }
                  placeholder={'Ingrese la holgura en minutos'}
                  disabled={disabledFields}
                  value={form.checkout_grace_period}
                  type="positive_integer"
                />
              )}
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default TaskDataForm;

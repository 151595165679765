import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalTransition, useModal } from 'react-simple-hook-modal';
import { useNavigate } from 'react-router-dom';
import AppService from 'services/AppService';
import { Navbar } from 'components/Organisms/Navbar/Navbar';
import { Navbar as KitNavbar } from 'components-kit/Organisms/Navbar/Navbar';
import { Navbar as GoNavbar } from 'components-go/Organisms/Navbar/Navbar';
import AuthAction from 'redux/actions/authActions/actions';
import BasicModal from 'components/Atoms/BasicModal/BasicModal';
import Text from 'components/Atoms/Text/Text';
import { Button } from 'components/Atoms/Button/Button';
import { Container } from 'components/Atoms/Container/Container';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import './App.scss';

interface AppInterface {
  children: React.ReactNode;
  notAuth?: boolean;
  loading?: boolean;
}

const AppTemplate = ({
  children,
  notAuth = false,
  loading = false,
}: AppInterface) => {
  const authStore = useSelector((state: any) => state.auth);
  const { isModalOpen, openModal, closeModal } = useModal();
  const history = useNavigate();
  const dispatch = useDispatch();

  const closeModalOnClick = () => {
    closeModal();
    window.location.href = '/';
  };

  const logOutOnClick = () => {
    AppService.logOut();
    dispatch(AuthAction.signOut({}));
  };

  return (
    <>
      <div className="app__main">
        {/* Overlay loader */}
        <Backdrop
          open={loading}
          sx={{
            position: 'fixed', // Cambia de 'absolute' a 'fixed' para cubrir toda la pantalla
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: '#fff',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Opcional: oscurecer el fondo
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {(authStore.position.roleId === 6 && ( // Kit
          <KitNavbar
            notAuth={notAuth}
            auth={authStore}
            withIcon
            logOutOnClick={logOutOnClick}
          />
        )) ||
          (authStore.position.roleId === 7 && ( // GoByTouch
            <GoNavbar
              notAuth={notAuth}
              auth={authStore}
              withIcon
              logOutOnClick={logOutOnClick}
            />
          )) || ( // Titask
            <Navbar
              notAuth={notAuth}
              auth={authStore}
              withIcon
              logOutOnClick={logOutOnClick}
            />
          )}
        <div
          className={`app__content${
            authStore.position.roleId === 6 ? ' app__content--kit' : ''
          }`}
        >
          {children}
        </div>
      </div>
      <Modal
        modalClassName="modal__main certifications__main"
        id="certifications-modal"
        isOpen={isModalOpen}
        transition={ModalTransition.SCALE}
      >
        <BasicModal title="Debes iniciar sesión" closeModal={closeModalOnClick}>
          <Text text="Hemos detectado que se ha iniciado sesión desde otro dispositivo, si desea continuar desde aquí por favor inicie sesión" />
          <div className="modal-button-container">
            <Container maxWidth="sm">
              <Button
                className="modal-button"
                text={'Iniciar sesión'}
                onClick={closeModalOnClick}
                size="large"
                variant="contained"
                border="circular"
                large
              />
            </Container>
          </div>
        </BasicModal>
      </Modal>
    </>
  );
};

export default AppTemplate;

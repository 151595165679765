import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CountrySelector from '../../components/Atoms/CountrySelector/CountrySelector';
import { NewStyleInput } from 'components/Atoms/NewStyleInput/NewStyleInput';
import { NewStyleButton } from 'components/Atoms/NewStyleButton/NewStyleButton';
import {
  NewStyleNotification,
  NotificationType,
} from 'components/Atoms/NewStyleNotification/NewStyleNotification';
import Country from '../../models/Country';
import portrait from '../../assets/images/logos/portrait.webp';
import logo from '../../assets/images/logos/logo_titask.webp';
import SignInService from '../SignIn/services/SignInService';
import AuthAction from '../../redux/actions/authActions/actions';

import './NewStyleLogin.scss';

interface FormData {
  email: string;
  password: string;
}

interface FormErrors {
  email?: string;
  password?: string;
}

interface NotificationState {
  type: NotificationType;
  message: string;
}

const excludeCountryCodes: string[] = [];

const NewStyleLogin: React.FC = () => {
  const dispatch = useDispatch();
  const { countries, localCountry } = useSelector((state: any) => state.helper);
  const excludedCodes = excludeCountryCodes.map((code) => code.toUpperCase());
  const availableCountries: Country[] = countries.filter(
    (country: Country) => !excludedCodes.includes(country.code.toUpperCase())
  );

  const getStoredCountry = () => {
    const storedCountry = localStorage.getItem('login_selected_country_db');
    return storedCountry ? JSON.parse(storedCountry) : null;
  };

  const initialSelectedCountry =
    getStoredCountry() ||
    availableCountries.find((c) => c.code === localCountry?.code) ||
    availableCountries[0] ||
    undefined;

  const [selectedCountry, setSelectedCountry] = useState<Country>(
    initialSelectedCountry
  );
  const [formData, setFormData] = useState<FormData>({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [notification, setNotification] = useState<NotificationState | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem(
      'login_selected_country_db',
      JSON.stringify(selectedCountry)
    );
  }, [selectedCountry]);

  const handleValidation =
    (field: keyof FormData) => (error: string | undefined) => {
      setErrors((prev) => ({ ...prev, [field]: error }));
    };

  const validateForm = () => {
    const newErrors: FormErrors = {};

    if (!formData.email) {
      newErrors.email = 'El correo electrónico es requerido';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Correo electrónico inválido';
    }

    if (!formData.password) {
      newErrors.password = 'La contraseña es requerida';
    } else if (formData.password.length < 6) {
      newErrors.password = 'La contraseña debe tener al menos 6 caracteres';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);

      try {
        if (!selectedCountry) {
          throw new Error('No se seleccionó un país.');
        }

        if (!formData.email) {
          throw new Error('No se ingresó un correo electrónico.');
        }

        if (!formData.password) {
          throw new Error('No se ingresó una contraseña.');
        }

        const response = await SignInService.login(
          formData.email,
          formData.password,
          selectedCountry.code
        );

        if (response.success) {
          dispatch(AuthAction.signIn(response.data));
          await timeout(250);
          window.location.reload();
        } else {
          throw new Error(
            response.errorMessage ||
              'Error no controlado en el servidor, comuníquese con el área de soporte.'
          );
        }
      } catch (error) {
        setNotification({
          type: 'danger',
          message: error instanceof Error ? error.message : 'Error desconocido',
        });
        setIsLoading(false);
      }
    } else {
      setNotification({
        type: 'danger',
        message: 'Debes de corregir los errores en los campos marcados.',
      });
    }
  };

  const validatePassword = (value: string) => {
    if (!value) return 'La contraseña es requerida';
    if (value.length < 6)
      return 'La contraseña debe tener al menos 6 caracteres';
    return undefined;
  };

  return (
    <div
      className="new-style-login-container"
      style={{ backgroundImage: `url(${portrait})` }}
    >
      <form className="new-style-login-form" onSubmit={handleSubmit}>
        <img src={logo} alt="Logo" className="new-style-login-logo" />

        <div className="country-container">
          <CountrySelector
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            countries={availableCountries}
          />
        </div>

        <NewStyleInput
          label="Correo electrónico"
          type="email"
          placeholder="Ingresa tu correo electrónico"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          error={errors.email}
          disabled={isLoading}
          onValidation={handleValidation('email')}
        />

        <NewStyleInput
          label="Contraseña"
          type="password"
          placeholder="Ingresa tu contraseña"
          value={formData.password}
          onChange={(e) =>
            setFormData({ ...formData, password: e.target.value })
          }
          error={errors.password}
          disabled={isLoading}
          validate={validatePassword}
          onValidation={handleValidation('password')}
        />

        <NewStyleButton type="submit" loading={isLoading}>
          Iniciar sesión
        </NewStyleButton>
      </form>

      {notification && (
        <NewStyleNotification
          type={notification.type}
          message={notification.message}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  );
};

export default NewStyleLogin;

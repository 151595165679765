import React, { useState, useEffect, createRef } from 'react';
import { Container } from 'components/Atoms/Container/Container';
import { ReactComponent as FileImportIcon } from 'assets/images/icons/file-import-green.svg';
import { ReactComponent as FileImportIconDisabled } from 'assets/images/icons/file-import-disabled.svg';
import moment from 'moment';
import TasksService from '../services/TasksService';
import {
  ROLE_PERMISSIONS,
  verifyPermissions,
} from 'routes/ProtectedRoutes.utils';
import { Button } from 'components/Atoms/Button/Button';
import TurnsService from 'scenes/Tasks/services/TurnsService';
import SimpleTable from 'components-kit/Organisms/SimpleTable/SimpleTable';
import {
  ColumnSimpleTableSchema,
  CustomSimpleTableRef,
  DataSourceSimpleTableSchema,
} from 'components-kit/Organisms/SimpleTable/SimpleTable.types';
import { useNavigate } from 'react-router-dom';
import FilterDetail from 'components-kit/Organisms/FilterDetail/FilterDetail';
import AttendanceTabFilterOption from 'scenes/Tasks/Actions/components/AttendanceTabFilterOption';
import { FilterDetailData } from 'components-kit/Organisms/FilterDetail/FilterDetail.types';
import { generateUniqueId, sleep } from 'utils/utils';
import AttendanceTabLoading from 'scenes/Tasks/Actions/components/AttendanceTabLoading';
import { useModal } from 'react-simple-hook-modal';
import AttendancePhotoModal from 'scenes/Tasks/Actions/components/AttendancePhotoModal';
import { API_TITASK_URL } from '../../../config/constants';
import AttendanceMapModal from 'scenes/Tasks/Actions/components/AttendanceMapModal';
import { ReactComponent as EditIcon } from 'assets/images/icons/edit-gray.svg';
import EditTitaskAttendanceModal from 'scenes/Tasks/Actions/components/EditTitaskAttendanceModal';
import { UpdateCheckStatesManually } from 'scenes/Kit/Attendances/components/EditAttendanceModal';
import AttendancesService from 'scenes/Kit/Attendances/services/AttendancesService';
import { toast } from 'react-toastify';
import TitaskAttendanceModal from 'components/Organisms/TitaskAttendanceModal/TitaskAttendanceModal';

import './AttendanceTable.scss';

export interface AttendaceTableSchema {
  taskId: number;
  permissions: any;
}

const columns: ColumnSimpleTableSchema[] = [
  {
    title: 'Tienda',
    index: 'storeNameFormat',
    align: 'left',
  },
  {
    title: 'Fecha',
    index: 'startOfExecutionDateFormat',
    align: 'left',
  },
  {
    title: 'Hora inicio',
    index: 'startOfExecutionTimeFormat',
    align: 'left',
  },
  {
    title: 'Hora fin',
    index: 'endOfExecutionTimeFormat',
    align: 'left',
  },
  {
    title: 'Nombre y Apellido',
    index: 'fullnameTaskerFormat',
    align: 'left',
  },
  {
    title: 'Checkin',
    index: 'checkinFormat',
    align: 'left',
  },
  {
    title: 'Checkout',
    index: 'checkoutFormat',
    align: 'left',
  },
  {
    title: 'Horas totales',
    index: 'diffHoursFormat',
    align: 'left',
  },
  {
    title: 'Editar',
    index: 'actions',
    align: 'center',
  },
];

const DEFAULT_COORDINATE: [number, number] = [
  -10.83116369801352, -63.665851569683085,
];
const DEFAULT_WITHOUT_SELECTED_MAP_ZOOM = 3;
const DEFAULT_SELECTED_MAP_ZOOM = 18;

const INITIAL_SELECTED_PHOTO = '';

const toastOptions: any = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};

const AttendaceTable = ({ taskId, permissions }: AttendaceTableSchema) => {
  const mainTableRef = createRef<CustomSimpleTableRef<any>>();
  const [tableLoading, setTableLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingAttendanceReport, setLoadingAttendanceReport] = useState(false);

  const navigate = useNavigate();

  /* Filters */
  const [startDateFilter, setStartDateFilter] = useState(
    moment().add(-2, 'month').format('YYYY-MM-DD')
  );
  const [startDateFilterTemp, setStartDateFilterTemp] = useState(
    moment().add(-2, 'month').toDate()
  );
  const [endDateFilter, setEndDateFilter] = useState(
    moment().format('YYYY-MM-DD')
  );
  const [endDateFilterTemp, setEndDateFilterTemp] = useState(moment().toDate());

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const {
    isModalOpen: isPhotoModalOpen,
    openModal: openPhotoModal,
    closeModal: closePhotoModal,
  } = useModal();

  const {
    isModalOpen: isMapModalOpen,
    openModal: openMapModal,
    closeModal: closeMapModal,
  } = useModal();

  const [currentSelectedPhoto, setCurrentSelectedPhoto] = useState(
    INITIAL_SELECTED_PHOTO
  );

  const [currentSelectedPhotoTitle, setCurrentSelectedPhotoTitle] =
    useState('');

  const [currentSelectedCoordinates, setCurrentSelectedCoordinates] =
    useState<[number, number]>(DEFAULT_COORDINATE);
  const [currentSelectedMapZoom, setCurrentSelectedMapZoom] = useState(
    DEFAULT_WITHOUT_SELECTED_MAP_ZOOM
  );

  const [userTurnIdSelected, setUserTurnIdSelected] = useState<
    string | undefined
  >(undefined);

  const {
    isModalOpen: isEditAttendanceModalOpen,
    openModal: openEditAttendanceModal,
    closeModal: closeEditAttendanceModal,
  } = useModal();

  const [isShowAttendanceModal, setIsShowAttendanceModal] = useState(false);

  const [loadingEditAttendanceModal, setLoadingEditAttendanceModal] =
    useState(false);

  const [tableRefreshUqId, setTableRefreshUqId] = useState(generateUniqueId());

  useEffect(() => {
    mainTableRef.current?.fetchData(1);
  }, [`${startDateFilter}-${endDateFilter}`]);

  useEffect(() => {
    if (!loading) {
      mainTableRef.current?.fetchData();
    }
  }, [tableRefreshUqId]);

  useEffect(() => {
    initLoad();
  }, []);

  const initLoad = async () => {
    await sleep(100);
    setLoading(false);
  };

  const closePhotoModalWrapper = async () => {
    closePhotoModal();
    await sleep(100);
    setCurrentSelectedPhoto(INITIAL_SELECTED_PHOTO);
  };

  const closeMapModalWrapper = async () => {
    closeMapModal();
    await sleep(100);
    setCurrentSelectedCoordinates(DEFAULT_COORDINATE);
    setCurrentSelectedMapZoom(DEFAULT_WITHOUT_SELECTED_MAP_ZOOM);
  };

  const photoOnClick = (s3Key: string, title: string) => {
    setCurrentSelectedPhoto(
      `${API_TITASK_URL}/file/download/${encodeURIComponent(s3Key)}`
    );
    setCurrentSelectedPhotoTitle(title);
    openPhotoModal();
  };

  const locationOnClick = (longitude: number, latitude: number) => {
    setCurrentSelectedCoordinates([latitude, longitude]);
    setCurrentSelectedMapZoom(DEFAULT_SELECTED_MAP_ZOOM);
    openMapModal();
  };

  const exportAttendanceOnClick = async () => {
    if (!loadingAttendanceReport) {
      setLoadingAttendanceReport(true);
      const response = await TurnsService.downloadAttendanceReportByTaskId(
        taskId,
        startDateFilter,
        endDateFilter
      );

      if (response.data) {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'export_attendance_by_task.xlsx');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }

      setLoadingAttendanceReport(false);
    }
  };

  const editAttendance = (row: any) => {
    setUserTurnIdSelected(`${row.userId}-${row.turnId}`);
    setIsShowAttendanceModal(true);
  };

  const fetchData = async (
    pageSize: number,
    currentPage: number
  ): Promise<DataSourceSimpleTableSchema<any>> => {
    setTableLoading(true);

    const attendanceResponse =
      await TasksService.getAssistanceByTaskIdWithFilters(
        taskId,
        startDateFilter,
        endDateFilter,
        currentPage,
        pageSize
      );

    setTableLoading(false);

    if (attendanceResponse.success) {
      //hh:mm A
      const attendanceData = attendanceResponse.data.data.map((row: any) => {
        let diffHours = row.startedAt ? 'En progreso' : 'Sin iniciar';

        if (row.startedAt && row.endedAt) {
          const init = moment(row.startedAt);
          const fin = moment(row.endedAt);
          diffHours = `${fin.diff(init, 'hour')}hrs ${moment(
            fin.diff(init)
          ).format('m')}min`;
        }

        const checkin = row.startedAt
          ? moment(row.startedAt).format('DD-MM-YYYY hh:mm A')
          : null;

        const checkout = row.endedAt
          ? moment(row.endedAt).format('DD-MM-YYYY hh:mm A')
          : null;

        return {
          ...row,
          storeNameFormat: (
            <p
              className="hyperlink-row"
              onClick={() => {
                console.log('Send store id: ', row.storeId);
                // navigate(`/stores/${row.storeId}`);
              }}
            >{`${row.storeName}`}</p>
          ),
          startOfExecutionDateFormat: moment(row.startOfExecutionDate).format(
            'DD-MM-YYYY'
          ),
          startOfExecutionTimeFormat: moment(row.startOfExecutionDate).format(
            'hh:mm A'
          ),
          endOfExecutionTimeFormat: moment(row.endOfExecutionDate).format(
            'hh:mm A'
          ),
          fullnameTaskerFormat: (
            <p
              className="hyperlink-row"
              onClick={() => {
                navigate(`/taskers/tasker-selected/${row.userId}`);
              }}
            >{`${row.userName} ${row.userLastname}`}</p>
          ),
          checkinFormat: checkin ? (
            <div className="checkin-row">{checkin}</div>
          ) : (
            <>-</>
          ),
          checkoutFormat: checkout ? (
            <div className="checkin-row">{checkout}</div>
          ) : (
            <>-</>
          ),
          diffHoursFormat: diffHours,
          actions: (
            <div className="row-actions__main">
              <EditIcon onClick={() => editAttendance(row)} />
            </div>
          ),
        };
      });

      return {
        items: attendanceData,
        totalRows: parseInt(attendanceResponse.data.totalRowsFiltered),
      };
    }

    return {
      items: [],
      totalRows: 0,
    };
  };

  const executeAdditionalFilters = async (
    startDateParam: string,
    endDateParam: string
  ) => {
    setStartDateFilter(startDateParam);
    setEndDateFilter(endDateParam);
  };

  const clearFilters = () => {
    console.log('clear filters');
  };

  const removeFilter = (key: string) => {
    console.log('remove filter', key);
  };

  const getActiveFilters = (): FilterDetailData[] => {
    const sd = moment(startDateFilter, 'YYYY-MM-DD').format('DD/MM/YYYY');
    const ed = moment(endDateFilter, 'YYYY-MM-DD').format('DD/MM/YYYY');

    const filters: FilterDetailData[] = [
      {
        key: 'DATE_RANGE_FILTER',
        label: 'Fecha',
        detail: `Del ${sd} al ${ed}`,
        disableRemove: true,
      },
    ];

    return filters;
  };

  const submitAttendance = async (params: UpdateCheckStatesManually) => {
    setLoadingEditAttendanceModal(true);

    const updateCheckStatesResponse = await AttendancesService.updateTimeCheck(
      params
    );

    setLoadingEditAttendanceModal(false);

    if (updateCheckStatesResponse.success) {
      setTableRefreshUqId(generateUniqueId());

      toast.success(
        'Se guardaron los cambios satisfactoriamente.',
        toastOptions
      );
      closeEditAttendanceModal();
    }
  };

  const handleCloseShowAttendanceModal = () => {
    setIsShowAttendanceModal(false);
  };

  const reloadTable = async () => {
    mainTableRef.current?.fetchData();
  };

  return (
    <>
      <Container>
        <div className="attendance__main">
          {!loading &&
            verifyPermissions(
              permissions,
              ROLE_PERMISSIONS.tasks.LOOK_ASISTANCE_LIST_TURN
            ) && (
              <>
                <div className="header__options" style={{ marginBottom: '0' }}>
                  <div
                    className="first__options"
                    style={{ visibility: 'hidden' }}
                  >
                    <div className="searchbar__main">
                      <div className="searchbar__content">
                        <input />
                      </div>
                    </div>
                  </div>
                  <div className="second__options">
                    <AttendanceTabFilterOption
                      isFilterOpen={isFilterOpen}
                      setIsFilterOpen={setIsFilterOpen}
                      startDate={startDateFilterTemp}
                      currentStartDate={startDateFilter}
                      setStartDate={setStartDateFilterTemp}
                      endDate={endDateFilterTemp}
                      currentEndDate={endDateFilter}
                      setEndDate={setEndDateFilterTemp}
                      submit={executeAdditionalFilters}
                    />

                    <Button
                      className="additional__buttons__simple__table"
                      onClick={exportAttendanceOnClick}
                      text={
                        loadingAttendanceReport
                          ? 'Procesando...'
                          : 'Exportar asistencia'
                      }
                      type="primary"
                      startIcon={
                        loadingAttendanceReport ? (
                          <FileImportIconDisabled />
                        ) : (
                          <FileImportIcon />
                        )
                      }
                      variant="outlined"
                      border="square"
                      large
                      disabled={loadingAttendanceReport}
                    />
                  </div>
                </div>

                {/* filter details */}
                <FilterDetail
                  filters={getActiveFilters()}
                  cleanFilters={clearFilters}
                  removeFilter={removeFilter}
                  loading={tableLoading}
                />

                <SimpleTable
                  ref={mainTableRef}
                  columns={columns}
                  request={fetchData}
                  initialDefaultPageSize={5}
                  loading={tableLoading}
                  minWidth={1500}
                />
              </>
            )}
          {loading && <AttendanceTabLoading />}
          {/* Modals */}
          <AttendancePhotoModal
            isModalOpen={isPhotoModalOpen}
            closeModal={closePhotoModalWrapper}
            photoUri={currentSelectedPhoto}
            title={currentSelectedPhotoTitle}
          />

          <AttendanceMapModal
            isModalOpen={isMapModalOpen}
            closeModal={closeMapModalWrapper}
            currentCoordinate={currentSelectedCoordinates}
            zoom={currentSelectedMapZoom}
          />

          {userTurnIdSelected && (
            <EditTitaskAttendanceModal
              isModalOpen={isEditAttendanceModalOpen}
              closeModal={closeEditAttendanceModal}
              submit={submitAttendance}
              userTurnId={userTurnIdSelected}
            />
          )}

          {userTurnIdSelected && (
            <TitaskAttendanceModal
              isOpen={isShowAttendanceModal}
              onClose={handleCloseShowAttendanceModal}
              id={userTurnIdSelected}
              fetchParentData={reloadTable}
            />
          )}
        </div>
      </Container>
    </>
  );
};

export default AttendaceTable;

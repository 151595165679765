import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CreateUser from 'scenes/Users/Create/Create';
import TasksActions from 'scenes/Tasks/Actions/TasksActions';
import UserDeteail from 'scenes/Taskers/Actions/TaskerSelected';
import CreateTask from 'scenes/Tasks/Create/Create';
import {
  CreateModule,
  PaymentsSchedule,
  Settings,
  SettingsCertifications,
  SettingsClients,
  SettingsCreateCertification,
  SettingsMaterials,
  SettingsPaymentSchedule,
  SettingsStores,
  SettingsVertical,
  Users,
  QrCode,
  Tasks,
  Taskers,
  Home,
  Profile,
  SignIn,
  RestorePassword,
  RestorePasswordV2,
  PageNotFound,
  TestPage,
  KitHome,
  KitPop,
  KitAttendances,
  KitLifts,
  KitSos,
  KitStock,
  KitSells,
  KitForms,
  KitSettings,
  KitBrands,
  KitClients,
  KitCategories,
  KitBrandCreate,
  KitCategoryCreate,
  KitClientCreate,
  KitViewCampaignV2,
  KitCreateTurn,
  KitCampaignsV2,
  KitCreateCampaignV2,
  KitViewTurnV2,
  KitStores,
  KitCreateStore,
  KitViewStore,
  KitSettingForms,
  KitSettingCreateForm,
  KitSettingViewForm,
  GoSettingView,
  GoSettingVerticalsView,
  GoAnnouncementsView,
  GoApplicationsView,
  GoSettingCompaniesView,
  GoSettingJobTypesView,
  GoSettingMedicsView,
  GoSettingDocumentsView,
  GoAssignedUsersView,
  CollectorUserTurnView,
  KitConfirmTurnView,
  GoAssignedUsersViewDetail,
  KitCreateMultipleTurn,
  KitSalesOutlet,
  KitModernSalesOutlet,
  KitTraditionalSalesOutlet,
  KitClientUpdate,
  KitBusinessChains,
  KitBusinessChainsCreate,
  KitBusinessChainsUpdate,
  KitCreateModernSalesOutlet,
  KitDetailModernSalesOutlet,
  KitCreateTraditionalSalesOutlet,
  KitTaskers,
  KitImporters,
  KitImportersShiftAndUserBulk,
  KitImportersStores,
  KitSettingOptionalForms,
  KitSettingCreateOptionalForm,
  KitSettingViewOptionalForm,
  KitOptionalForms,
} from './routes.utils';
import { DEFAULT_LANGUAGE } from 'config/constants';
import PrivateRoute from 'routes/components/PrivateRoute';
import GuestRoute from 'routes/components/GuestRoute';
import CountryService from 'services/CountryService';
import Country from 'models/Country';
import HelperAction from '../redux/actions/helper/actions';
import i18n from 'utils/i18n';
import NewStyleLogin from 'scenes/NewStyleLogin';

const AppRoutes = () => {
  const dispatch = useDispatch();
  const { localCountry } = useSelector((state: any) => state.helper);
  const authUser = useSelector((state: any) => state.auth);

  useEffect(() => {
    countryFromApiResponse();
  }, []);

  const countryFromApiResponse = async () => {
    const availableCountriesResponse =
      await CountryService.getAvailableCountries();
    let countriesAux = [];
    let localCountryAux: Country | undefined = undefined;

    if (availableCountriesResponse.success) {
      countriesAux = availableCountriesResponse.data;
    } else {
      console.error(availableCountriesResponse.errorMessage);
    }
    dispatch(
      HelperAction.save({
        countries: countriesAux,
        localCountry: localCountry,
      })
    );

    if (!localCountry?.code) {
      const localCountryCode = await CountryService.getLocalCountryFromApi();
      localCountryAux = countriesAux.find(
        (country: Country) =>
          country.code.toUpperCase() === localCountryCode.toUpperCase()
      );
      dispatch(
        HelperAction.save({
          countries: countriesAux,
          localCountry: localCountryAux as Country,
        })
      );
    }

    if (authUser.id === 0) {
      await i18n.changeLanguage(localCountryAux?.language || DEFAULT_LANGUAGE);
    } else {
      await i18n.changeLanguage(authUser.language);
    }
  };

  return (
    <Routes>
      <Route path="/" element={<GuestRoute outlet={<NewStyleLogin />} />} />
      <Route
        path="payments-schedule"
        element={<PrivateRoute outlet={<PaymentsSchedule />} />}
      />
      <Route path="settings" element={<PrivateRoute outlet={<Settings />} />} />
      <Route
        path="settings/certifications"
        element={<PrivateRoute outlet={<SettingsCertifications />} />}
      />
      <Route
        path="settings/certifications/create"
        element={<PrivateRoute outlet={<SettingsCreateCertification />} />}
      />
      <Route
        path="settings/certifications/create-module"
        element={<PrivateRoute outlet={<CreateModule />} />}
      />
      <Route
        path="settings/clients"
        element={<PrivateRoute outlet={<SettingsClients />} />}
      />
      <Route
        path="settings/verticals"
        element={<PrivateRoute outlet={<SettingsVertical />} />}
      />
      <Route
        path="settings/materials"
        element={<PrivateRoute outlet={<SettingsMaterials />} />}
      />
      <Route
        path="settings/stores"
        element={<PrivateRoute outlet={<SettingsStores />} />}
      />
      <Route
        path="settings/payment-schedule"
        element={<PrivateRoute outlet={<SettingsPaymentSchedule />} />}
      />
      <Route path="users" element={<PrivateRoute outlet={<Users />} />} />
      <Route
        path="users/create"
        element={<PrivateRoute outlet={<CreateUser />} />}
      />
      <Route path="tasks" element={<PrivateRoute outlet={<Tasks />} />} />
      <Route
        path="tasks/view/:id"
        element={<PrivateRoute outlet={<TasksActions />} />}
      />
      <Route
        path="tasks/create"
        element={<PrivateRoute outlet={<CreateTask />} />}
      />
      <Route path="qr-code" element={<PrivateRoute outlet={<QrCode />} />} />
      <Route path="taskers" element={<PrivateRoute outlet={<Taskers />} />} />
      <Route
        path="taskers/tasker-selected/:id"
        element={<PrivateRoute outlet={<UserDeteail />} />}
      />
      <Route path="profile" element={<PrivateRoute outlet={<Profile />} />} />
      <Route path="/create-password/:token" element={<RestorePassword />} />
      <Route path="/create-new-password" element={<RestorePasswordV2 />} />
      <Route
        path="/test"
        element={
          <PrivateRoute
            outlet={<TestPage />}
            permissionsRequired={'ACCESS_TO_TEST_PAGE'}
          />
        }
      />
      <Route path="/home" element={<PrivateRoute outlet={<Home />} />} />
      {/** START KIT ROUTES */}
      <Route path="/kit/home" element={<PrivateRoute outlet={<KitHome />} />} />
      <Route
        path="kit/profile"
        element={<PrivateRoute outlet={<Profile />} />}
      />
      <Route
        path="/kit/taskers"
        element={<PrivateRoute outlet={<KitTaskers />} />}
      />
      <Route
        path="/kit/attendances"
        element={<PrivateRoute outlet={<KitAttendances />} />}
      />
      <Route
        path="/kit/lifts"
        element={<PrivateRoute outlet={<KitLifts />} />}
      />
      <Route
        path="/kit/lifts/pop"
        element={<PrivateRoute outlet={<KitPop />} />}
      />
      <Route
        path="/kit/lifts/sos"
        element={<PrivateRoute outlet={<KitSos />} />}
      />
      <Route
        path="/kit/lifts/stock-and-prices"
        element={<PrivateRoute outlet={<KitStock />} />}
      />
      <Route
        path="/kit/lifts/sells"
        element={<PrivateRoute outlet={<KitSells />} />}
      />
      <Route
        path="/kit/lifts/forms"
        element={<PrivateRoute outlet={<KitForms />} />}
      />
      <Route
        path="/kit/lifts/additional-forms"
        element={<PrivateRoute outlet={<KitOptionalForms />} />}
      />
      <Route
        path="/kit/campaign/turn/:turnId"
        element={<PrivateRoute outlet={<KitViewTurnV2 />} />}
      />
      <Route
        path="/kit/campaign/collector-users-by-turn/:turnId"
        element={<PrivateRoute outlet={<CollectorUserTurnView />} />}
      />
      <Route
        path="/confirm-subscribed-turn/:token"
        element={<KitConfirmTurnView />}
      />
      <Route
        path="/kit/campaign/:campaignId/create-turn"
        element={<PrivateRoute outlet={<KitCreateTurn />} />}
      />
      <Route
        path="/kit/campaign/:campaignId/create-multiple-turn"
        element={<PrivateRoute outlet={<KitCreateMultipleTurn />} />}
      />
      <Route
        path="/kit/campaign/:campaignId"
        element={<PrivateRoute outlet={<KitViewCampaignV2 />} />}
      />
      <Route
        path="/kit/campaign"
        element={<PrivateRoute outlet={<KitCampaignsV2 />} />}
      />
      <Route
        path="/kit/campaign/create"
        element={<PrivateRoute outlet={<KitCreateCampaignV2 />} />}
      />
      <Route
        path="/kit/settings"
        element={<PrivateRoute outlet={<KitSettings />} />}
      />
      <Route
        path="/kit/settings/brands"
        element={<PrivateRoute outlet={<KitBrands />} />}
      />
      <Route
        path="/kit/settings/brands/create"
        element={<PrivateRoute outlet={<KitBrandCreate />} />}
      />
      <Route
        path="/kit/settings/clients"
        element={<PrivateRoute outlet={<KitClients />} />}
      />
      <Route
        path="/kit/settings/clients/create"
        element={<PrivateRoute outlet={<KitClientCreate />} />}
      />
      <Route
        path="/kit/settings/clients/:clientId"
        element={<PrivateRoute outlet={<KitClientUpdate />} />}
      />
      <Route
        path="/kit/settings/business-chains"
        element={<PrivateRoute outlet={<KitBusinessChains />} />}
      />
      <Route
        path="/kit/settings/business-chains/create"
        element={<PrivateRoute outlet={<KitBusinessChainsCreate />} />}
      />
      <Route
        path="/kit/settings/business-chains/:businessChainId"
        element={<PrivateRoute outlet={<KitBusinessChainsUpdate />} />}
      />
      <Route
        path="/kit/settings/stores/create"
        element={<PrivateRoute outlet={<KitCreateStore />} />}
      />
      <Route
        path="/kit/settings/stores/:storeId"
        element={<PrivateRoute outlet={<KitViewStore />} />}
      />
      <Route
        path="/kit/settings/stores"
        element={<PrivateRoute outlet={<KitStores />} />}
      />

      <Route
        path="/kit/settings/categories"
        element={<PrivateRoute outlet={<KitCategories />} />}
      />
      <Route
        path="/kit/settings/categories/create"
        element={<PrivateRoute outlet={<KitCategoryCreate />} />}
      />
      <Route
        path="/kit/settings/forms/:formId"
        element={<PrivateRoute outlet={<KitSettingViewForm />} />}
      />
      <Route
        path="/kit/settings/additional-forms/:formId"
        element={<PrivateRoute outlet={<KitSettingViewOptionalForm />} />}
      />
      <Route
        path="/kit/settings/forms/create"
        element={<PrivateRoute outlet={<KitSettingCreateForm />} />}
      />
      <Route
        path="/kit/settings/additional-forms/create"
        element={<PrivateRoute outlet={<KitSettingCreateOptionalForm />} />}
      />
      <Route
        path="/kit/settings/forms"
        element={<PrivateRoute outlet={<KitSettingForms />} />}
      />
      <Route
        path="/kit/settings/additional-forms"
        element={<PrivateRoute outlet={<KitSettingOptionalForms />} />}
      />
      <Route
        path="/kit/settings/sales-outlets"
        element={<PrivateRoute outlet={<KitSalesOutlet />} />}
      />
      <Route
        path="/kit/settings/sales-outlets/modern"
        element={<PrivateRoute outlet={<KitModernSalesOutlet />} />}
      />
      <Route
        path="/kit/settings/sales-outlets/traditional"
        element={<PrivateRoute outlet={<KitTraditionalSalesOutlet />} />}
      />
      <Route
        path="/kit/settings/sales-outlets/modern/create"
        element={<PrivateRoute outlet={<KitCreateModernSalesOutlet />} />}
      />
      <Route
        path="/kit/settings/sales-outlets/traditional/create"
        element={<PrivateRoute outlet={<KitCreateTraditionalSalesOutlet />} />}
      />
      <Route
        path="/kit/settings/sales-outlets/:salesOutletId"
        element={<PrivateRoute outlet={<KitDetailModernSalesOutlet />} />}
      />
      <Route
        path="/kit/importers"
        element={<PrivateRoute outlet={<KitImporters />} />}
      />
      <Route
        path="/kit/importers/shift-and-user-bulk"
        element={<PrivateRoute outlet={<KitImportersShiftAndUserBulk />} />}
      />
      <Route
        path="/kit/importers/stores"
        element={<PrivateRoute outlet={<KitImportersStores />} />}
      />
      {/** END KIT ROUTES */}
      {/* Rutas Go*/}
      <Route
        path="/go/announcements"
        element={
          <PrivateRoute
            outlet={<GoAnnouncementsView />}
            permissionsRequired={'GBT_SHOW_ANNOUNCEMENTS'}
          />
        }
      />
      <Route
        path="/go/announcements/:announcementId"
        element={
          <PrivateRoute
            outlet={<GoApplicationsView />}
            permissionsRequired={'GBT_SHOW_APPLICANTS'}
          />
        }
      />
      <Route
        path="/go/assigned-users"
        element={
          <PrivateRoute
            outlet={<GoAssignedUsersView />}
            permissionsRequired={'GBT_SHOW_ASSIGNED_APPLICANTS'}
          />
        }
      />
      <Route
        path="go/assigned-users/:userId"
        element={
          <PrivateRoute
            outlet={<GoAssignedUsersViewDetail />}
            permissionsRequired={'GBT_SHOW_ASSIGNED_APPLICANTS'}
          />
        }
      />
      <Route
        path="/go/settings"
        element={
          <PrivateRoute
            outlet={<GoSettingView />}
            permissionsRequired={'GBT_SHOW_SETTINGS'}
          />
        }
      />
      <Route
        path="/go/settings/verticals"
        element={
          <PrivateRoute
            outlet={<GoSettingVerticalsView />}
            permissionsRequired={['GBT_SHOW_SETTINGS', 'GBT_ST_SHOW_POSITIONS']}
          />
        }
      />
      <Route
        path="/go/settings/companies"
        element={
          <PrivateRoute
            outlet={<GoSettingCompaniesView />}
            permissionsRequired={['GBT_SHOW_SETTINGS', 'GBT_ST_SHOW_COMPANIES']}
          />
        }
      />
      <Route
        path="/go/settings/job-types"
        element={
          <PrivateRoute
            outlet={<GoSettingJobTypesView />}
            permissionsRequired={['GBT_SHOW_SETTINGS', 'GBT_ST_SHOW_JOB_TYPES']}
          />
        }
      />
      <Route
        path="/go/settings/medics"
        element={
          <PrivateRoute
            outlet={<GoSettingMedicsView />}
            permissionsRequired={['GBT_SHOW_SETTINGS', 'GBT_ST_SHOW_MEDICS']}
          />
        }
      />
      <Route
        path="/go/settings/documents"
        element={
          <PrivateRoute
            outlet={<GoSettingDocumentsView />}
            permissionsRequired={['GBT_SHOW_SETTINGS', 'GBT_ST_SHOW_DOCUMENTS']}
          />
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;

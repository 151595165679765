import dayjs from 'dayjs';

interface Location {
  latitude: number;
  longitude: number;
}

export interface CheckProcessSchema {
  executedDate: dayjs.Dayjs;
  location: Location | null;
  photoUrl: string | null;
  checkedBy: {
    id: number;
    fullName: string;
    isOwner: boolean;
  };
}

export interface Form {
  formId: number;
  name: string;
  resolvedData: {
    date: dayjs.Dayjs;
    id: string; //formId-userId-turnId-id o id (additionalFormId)
  } | null;
}

export interface AttendanceData {
  id: {
    userId: number;
    turnId: number;
  };
  name: string;
  lastname: string;
  email: string;
  document: {
    id: string;
    type: string;
    number: string;
  } | null;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  store: {
    id: number;
    name: string;
    location: Location | null;
  };
  campaign: {
    id: number;
    name: string;
  };
  vertical: {
    id: number;
    name: string;
  };
  company: {
    id: number;
    name: string;
  };
  checkIn: CheckProcessSchema | null;
  checkOut: CheckProcessSchema | null;
  forms: Form[];
  otherForms: Form[];
}

//Fórmula de Haversine
export function isFartherThan(
  point1: Location,
  point2: Location,
  distanceThreshold: number
): boolean {
  const R = 6371e3; // Radio de la Tierra en metros
  const toRadians = (deg: number) => (deg * Math.PI) / 180;

  const φ1 = toRadians(point1.latitude);
  const φ2 = toRadians(point2.latitude);
  const Δφ = toRadians(point2.latitude - point1.latitude);
  const Δλ = toRadians(point2.longitude - point1.longitude);

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distancia en metros

  return distance > distanceThreshold;
}

export function getDistanceInMeters(
  point1: Location | null,
  point2: Location | null
): number | null {
  if (point1 == null || point2 === null) {
    return null;
  }
  const R = 6371e3; // Radio de la Tierra en metros
  const toRadians = (deg: number) => (deg * Math.PI) / 180;

  const φ1 = toRadians(point1.latitude);
  const φ2 = toRadians(point2.latitude);
  const Δφ = toRadians(point2.latitude - point1.latitude);
  const Δλ = toRadians(point2.longitude - point1.longitude);

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Retorna la distancia en metros
}

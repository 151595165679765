import React, { useEffect } from 'react';
import { AlertCircle, Info, AlertTriangle, X } from 'lucide-react';

import './NewStyleNotification.scss';

export type NotificationType = 'info' | 'danger' | 'warning';

interface NewStyleNotificationProps {
  type: NotificationType;
  message: string;
  onClose?: () => void;
  autoClose?: boolean;
  duration?: number;
}

const icons = {
  info: <Info size={24} />,
  danger: <AlertCircle size={24} />,
  warning: <AlertTriangle size={24} />,
};

export const NewStyleNotification: React.FC<NewStyleNotificationProps> = ({
  type,
  message,
  onClose,
  autoClose = true,
  duration = 5000,
}) => {
  useEffect(() => {
    if (autoClose && onClose) {
      const timer = setTimeout(onClose, duration);
      return () => clearTimeout(timer);
    }
  }, [autoClose, duration, onClose]);

  return (
    <div className={`new-style-notification new-style-notification-${type}`}>
      <div className="new-style-notification-icon">{icons[type]}</div>
      <div className="new-style-notification-content">{message}</div>
      {onClose && (
        <button className="new-style-notification-close" onClick={onClose}>
          <X size={20} />
        </button>
      )}
    </div>
  );
};

import React from 'react';

import './GenericSkeleton.scss';

function GenericSkeleton() {
  return (
    <div className="generic-skeleton-container">
      <div className="generic-skeleton-form-group">
        <div className="generic-skeleton-label"></div>
        <div className="generic-skeleton-input"></div>
      </div>

      <div className="generic-skeleton-form-group">
        <div className="generic-skeleton-label"></div>
        <div className="generic-skeleton-select"></div>
      </div>
      <div className="generic-skeleton-two-columns">
        <div className="generic-skeleton-form-group">
          <div className="generic-skeleton-label"></div>
          <div className="generic-skeleton-select"></div>
        </div>

        <div className="generic-skeleton-form-group">
          <div className="generic-skeleton-label"></div>
          <div className="generic-skeleton-select"></div>
        </div>
      </div>
    </div>
  );
}

export default GenericSkeleton;

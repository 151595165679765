import React, { ButtonHTMLAttributes } from 'react';
import { Loader2 } from 'lucide-react';

import './NewStyleButton.scss';

interface NewStyleButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  loading?: boolean;
}

export const NewStyleButton: React.FC<NewStyleButtonProps> = ({
  children,
  className = '',
  loading = false,
  disabled,
  ...props
}) => {
  return (
    <button
      className={`new-style-button-base ${
        loading ? 'loading' : ''
      } ${className}`}
      disabled={loading || disabled}
      {...props}
    >
      {loading ? (
        <>
          <Loader2 size={20} className="new-style-button-spinner" />
          <span>{children}</span>
        </>
      ) : (
        children
      )}
    </button>
  );
};

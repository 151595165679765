import React, { Fragment } from 'react';
import { TextField } from '@material-ui/core';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

import './TextInput.scss';
interface TextInputInterface {
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  errors?: any;
  errorClassName?: string;
  inputProps?: any;
  inputPropsAdornments?: any;
  isValid?: boolean;
  label?: string;
  labelColor?: string;
  large?: boolean;
  multiline?: boolean;
  name: string;
  onChange?: (event: any) => void;
  placeholder?: string;
  register?: any;
  rows?: number;
  type?: string;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  value?: any;
  onEnterAction?: () => void;
  onBlur?: (event: any) => void;
  onKeyDown?: (event: any) => void;
}

const TextInput = ({
  className = '',
  defaultValue = '',
  error = false,
  errors = null,
  errorClassName = '',
  inputProps,
  inputPropsAdornments,
  label = '',
  labelColor,
  multiline = false,
  name,
  onChange,
  large = false,
  placeholder = '',
  register = null,
  rows = 0,
  type = 'string',
  variant = 'outlined',
  onEnterAction,
  onBlur,
  onKeyDown,
  ...rest
}: TextInputInterface) => {
  if (className) className = ` ${className}`;
  const inputPropsRequired = inputProps && inputProps.required ? '*' : '';

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      'Home', // Permitir Inicio (Home)
      'End', // Permitir Fin (End)
    ];

    // Permitir Ctrl + C y Ctrl + V
    if (event.ctrlKey && ['c', 'v'].includes(event.key.toLowerCase())) {
      return;
    }

    // Permitir Shift + Home y Shift + End
    if (event.shiftKey && (event.key === 'Home' || event.key === 'End')) {
      return;
    }

    // Permitir solo números
    if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = event.clipboardData.getData('text');

    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault();
    }
  };

  return large ? (
    <div className={`textfield__large`}>
      {label !== '' && (
        <p
          className={`textfield__label`}
          style={{ color: labelColor }}
        >{`${label}${inputPropsRequired}`}</p>
      )}
      <TextField
        className={`textfield__main${className}`}
        defaultValue={defaultValue}
        error={error}
        id={name}
        inputProps={inputProps}
        InputProps={{
          style: {
            backgroundColor: 'white',
          },
          ...inputPropsAdornments,
        }}
        multiline={multiline}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        minRows={rows}
        type={type}
        variant={variant}
        {...(register && register)}
        {...rest}
        onBlur={onblur}
        onKeyDown={
          onKeyDown
            ? onKeyDown
            : type === 'positive_integer'
            ? handleKeyDown
            : undefined
        }
        onPaste={type === 'positive_integer' ? handlePaste : undefined}
      />
      {errors && errors[name] && <ErrorMessage className={errorClassName} />}
    </div>
  ) : (
    <Fragment>
      {label !== '' && (
        <p
          className={`textfield__label`}
          style={{ color: labelColor }}
        >{`${label}`}</p>
      )}
      <TextField
        className={`textfield__main${className}`}
        defaultValue={defaultValue}
        id={name}
        inputProps={inputProps}
        InputProps={{
          style: {
            backgroundColor: 'white',
          },
          ...inputPropsAdornments,
        }}
        multiline={multiline}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        minRows={rows}
        type={type}
        variant={variant}
        {...(register && register)}
        {...rest}
        onBlur={onblur}
        onKeyDown={
          onEnterAction
            ? (event) => {
                if (event.key === 'Enter' && onEnterAction) {
                  onEnterAction();
                }
              }
            : onKeyDown
            ? onKeyDown
            : type === 'positive_integer'
            ? handleKeyDown
            : undefined
        }
        onPaste={type === 'positive_integer' ? handlePaste : undefined}
      />
    </Fragment>
  );
};

export default TextInput;

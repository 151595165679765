import React, { InputHTMLAttributes, useState, useEffect } from 'react';
import {
  Mail,
  Lock,
  DivideIcon as LucideIcon,
  AlertCircle,
  Eye,
  EyeOff,
} from 'lucide-react';

import './NewStyleInput.scss';

interface NewStyleInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  icon?: typeof LucideIcon | null;
  disabled?: boolean;
  validate?: (value: string) => string | undefined;
  onValidation?: (error: string | undefined) => void;
}

const defaultIcons: Record<string, typeof LucideIcon> = {
  email: Mail,
  password: Lock,
};

const defaultValidators = {
  email: (value: string) => {
    if (!value) return 'El correo electrónico es requerido';
    if (!/\S+@\S+\.\S+/.test(value)) return 'Correo electrónico inválido';
    return undefined;
  },
};

export const NewStyleInput: React.FC<NewStyleInputProps> = ({
  label,
  error,
  className = '',
  type,
  icon: customIcon,
  disabled,
  validate,
  onValidation,
  onChange,
  value,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [localError, setLocalError] = useState<string | undefined>(error);
  const [showPassword, setShowPassword] = useState(false);
  const IconComponent =
    customIcon !== undefined ? customIcon : defaultIcons[type || ''];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);

    const validator =
      validate || (type === 'email' ? defaultValidators.email : undefined);
    if (validator) {
      const validationError = validator(e.target.value);
      setLocalError(validationError);
      onValidation?.(validationError);
    }
  };

  useEffect(() => {
    setLocalError(error);
  }, [error]);

  const handleBlur = () => {
    setIsFocused(false);
    const validator =
      validate || (type === 'email' ? defaultValidators.email : undefined);
    if (validator && typeof value === 'string') {
      const validationError = validator(value);
      setLocalError(validationError);
      onValidation?.(validationError);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="new-style-input-wrapper">
      <label className="new-style-input-label">{label}</label>
      <div className="new-style-input-container">
        {IconComponent && (
          <div
            className={`new-style-input-icon ${
              isFocused ? (localError ? 'error' : 'focused') : ''
            }`}
            style={{
              color: localError ? '#FF4444' : isFocused ? '#072c85' : '#9CA3AF',
            }}
          >
            <IconComponent size={20} />
          </div>
        )}
        <input
          className={`new-style-input-field ${localError ? 'error' : ''} ${
            isFocused ? 'focused' : ''
          } ${IconComponent ? 'with-icon' : ''} ${
            type === 'password' ? 'with-action' : ''
          } ${className}`}
          type={type === 'password' && showPassword ? 'text' : type}
          disabled={disabled}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          onChange={handleChange}
          value={value}
          {...props}
        />
        {type === 'password' && (
          <button
            type="button"
            className="new-style-input-action"
            onClick={togglePasswordVisibility}
            tabIndex={-1}
          >
            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
          </button>
        )}
        {localError && (
          <div className="new-style-tooltip">
            <AlertCircle size={14} style={{ marginRight: '4px' }} />
            {localError}
          </div>
        )}
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { X, Calendar, UserCircle, Building, Briefcase } from 'lucide-react';
import dayjs from 'dayjs';
import { API_TITASK_URL } from '../../../config/constants';
import { getMainDateConfiguration } from 'utils/utils';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CheckCard from 'components/Organisms/TitaskAttendanceModal/CheckCard';
import { AttendanceData } from 'components/Organisms/TitaskAttendanceModal/Models/AttendanceData';
import AttendancesService from 'scenes/Kit/Attendances/services/AttendancesService';
import ReactDOM from 'react-dom';

import 'react-day-picker/dist/style.css';
import 'components-kit/Organisms/AttendanceModal/AttendanceModal.scss';
import { UpdateCheckStatesManually } from 'scenes/Tasks/Actions/components/EditTitaskAttendanceModal';

const toastOptions: any = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};

interface TitaskAttendanceModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  fetchParentData?: () => void;
}

const TitaskAttendanceModal: React.FC<TitaskAttendanceModalProps> = ({
  isOpen,
  onClose,
  id,
  fetchParentData,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const userAuth = useSelector((state: any) =>
    state?.auth ? state?.auth : []
  );
  const [attendanceData, setAttendanceData] = useState<AttendanceData>();
  const mainDateFormat = getMainDateConfiguration(userAuth);

  // Estado para almacenar la referencia del portal
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(
    null
  );

  useEffect(() => {
    // Crear el contenedor del portal y agregarlo al body
    const portalDiv = document.createElement('div');
    portalDiv.setAttribute('id', 'titask-attendance-modal-root');
    document.body.prepend(portalDiv); // Lo agrega al principio del body
    setPortalContainer(portalDiv); // Guardar referencia en el estado

    return () => {
      portalDiv.remove(); // Eliminar nodo al desmontar
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      fetchData(id);
    } else {
      setAttendanceData(undefined);
    }
  }, [isOpen]);

  const getFormattedData = (mainData: any): AttendanceData => {
    return {
      id: {
        userId: mainData.userId,
        turnId: mainData.turnId,
      },
      name: mainData.userName,
      lastname: mainData.userLastname,
      email: mainData.userEmail,
      document:
        mainData.userDocumentNumber &&
        mainData.userDocumentTypeEs &&
        mainData.userDocumentTypeEn &&
        mainData.userDocumentTypeKey
          ? {
              id: mainData.userDocumentTypeKey,
              type: mainData.userDocumentTypeEs,
              number: mainData.userDocumentNumber,
            }
          : null,
      startDate: dayjs(mainData.startOfExecutionDate),
      endDate: dayjs(mainData.endOfExecutionDate),
      store: {
        id: mainData.storeId,
        name: mainData.storeName,
        location:
          (mainData.storeLatitude || mainData.storeLatitude === 0) &&
          (mainData.storeLongitude || mainData.storeLongitude === 0)
            ? {
                latitude: mainData.storeLatitude,
                longitude: mainData.storeLongitude,
              }
            : null,
      },
      campaign: {
        id: mainData.taskId,
        name: mainData.taskName,
      },
      vertical: {
        id: mainData.verticalId,
        name: mainData.verticalName,
      },
      company: {
        id: mainData.companyId,
        name: mainData.companyName,
      },
      checkIn:
        mainData.startedAt && mainData.startedUserId
          ? {
              executedDate: dayjs(mainData.startedAt),
              photoUrl: mainData.startedPhotoS3Key
                ? `${API_TITASK_URL}/file/get?key=${mainData.startedPhotoS3Key}`
                : null,
              location:
                (mainData.startedLatitude || mainData.startedLatitude === 0) &&
                (mainData.startedLongitude || mainData.startedLongitude === 0)
                  ? {
                      latitude: mainData.startedLatitude,
                      longitude: mainData.startedLongitude,
                    }
                  : null,
              checkedBy: {
                id: mainData.startedUserId,
                fullName: mainData.startedUser || `${mainData.startedUserId}`,
                isOwner: `${mainData.userId}` === `${mainData.startedUserId}`,
              },
            }
          : null,
      checkOut:
        mainData.endedAt && mainData.endedUserId
          ? {
              executedDate: dayjs(mainData.endedAt),
              photoUrl: mainData.endedPhotoS3Key
                ? `${API_TITASK_URL}/file/get?key=${mainData.endedPhotoS3Key}`
                : null,
              location:
                (mainData.endedLatitude || mainData.endedLatitude === 0) &&
                (mainData.endedLongitude || mainData.endedLongitude === 0)
                  ? {
                      latitude: mainData.endedLatitude,
                      longitude: mainData.endedLongitude,
                    }
                  : null,
              checkedBy: {
                id: mainData.endedUserId,
                fullName: mainData.endedUser || `${mainData.endedUserId}`,
                isOwner: `${mainData.userId}` === `${mainData.endedUserId}`,
              },
            }
          : null,
    };
  };

  const fetchData = async (id: string, withoutLoading = false) => {
    const userId = parseInt(id.split('-')[0]) || 0;
    const turnId = parseInt(id.split('-')[1]) || 0;

    if (!withoutLoading) {
      setIsLoading(true);
    }

    const response =
      await AttendancesService.getAttendanceForTitaskByUserTurnKey(
        userId,
        turnId
      );

    if (response.success) {
      const formattedData = getFormattedData(response.data);
      setAttendanceData(formattedData);
      if (!withoutLoading) {
        setIsLoading(false);
      }
    }
  };

  const renderSkeleton = () => (
    <div className="attendance-skeleton">
      <div className="attendance-skeleton-header">
        <div className="attendance-skeleton-user">
          <div className="attendance-skeleton-avatar" />
          <div className="attendance-skeleton-details">
            <div className="attendance-skeleton-name" />
            <div className="attendance-skeleton-document" />
          </div>
        </div>
        <div className="attendance-skeleton-info">
          <div className="attendance-skeleton-date" />
          <div className="attendance-skeleton-workplace">
            <div className="attendance-skeleton-pill" />
            <div className="attendance-skeleton-pill" />
          </div>
        </div>
      </div>
      <div className="attendance-skeleton-timeline">
        <div className="attendance-skeleton-entry" />
        <div className="attendance-skeleton-entry" />
      </div>
    </div>
  );

  const submitCheck = async (sendingParams: UpdateCheckStatesManually) => {
    const updateCheckStatesResponse = await AttendancesService.updateTimeCheck(
      sendingParams
    );

    if (updateCheckStatesResponse) {
      await fetchData(id, true);
      if (fetchParentData) {
        await fetchParentData();
      }
      toast.success(
        'Se guardaron los cambios satisfactoriamente.',
        toastOptions
      );
    }
  };

  if (!isOpen || !portalContainer) return null;

  const modalContent = (
    <div className="attendance-overlay">
      {isLoading || !attendanceData ? (
        renderSkeleton()
      ) : (
        <div className="attendance-content">
          <button className="attendance-close-button" onClick={onClose}>
            <X size={20} />
          </button>

          <div className="attendance-header">
            <div className="attendance-user-info">
              <div className="attendance-avatar">
                <UserCircle size={48} />
              </div>
              <div className="attendance-details">
                <h2>{`${attendanceData.name} ${attendanceData.lastname}`}</h2>
                <span className="attendance-document">
                  {attendanceData.document
                    ? `${attendanceData.document.type}: ${attendanceData.document.number}`
                    : 'Sin documento registrado'}
                </span>
              </div>
            </div>

            <div className="attendance-shift-info">
              <div className="attendance-date">
                <Calendar size={20} />
                <span title="Hora de inicio de turno">
                  {attendanceData.startDate.format(`${mainDateFormat} h:mmA`)}
                </span>
              </div>
              <div className="attendance-workplace">
                <div className="attendance-pill" title="Campaña">
                  <Briefcase size={16} />
                  <span>{attendanceData.campaign.name}</span>
                </div>
                <div
                  className="attendance-pill"
                  title="Tienda o punto de venta"
                >
                  <Building size={16} />
                  <span>{attendanceData.store.name}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="attendance-timeline">
            <CheckCard
              checkData={attendanceData.checkIn}
              type="in"
              submit={submitCheck}
              attendanceData={attendanceData}
            />
            <CheckCard
              checkData={attendanceData.checkOut}
              type="out"
              submit={submitCheck}
              attendanceData={attendanceData}
            />
          </div>
        </div>
      )}
    </div>
  );

  return ReactDOM.createPortal(modalContent, portalContainer);
};

export default TitaskAttendanceModal;

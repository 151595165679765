import React from 'react';
import { X } from 'lucide-react';

interface PhotoDetailModalProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  photoUrl: string;
}

function PhotoDetailModal({
  isOpen,
  handleCloseModal,
  photoUrl,
}: PhotoDetailModalProps) {
  return (
    <>
      {isOpen && (
        <div className="attendance-overlay">
          <div className="attendance-photo-modal">
            <button
              className="attendance-close-button"
              onClick={handleCloseModal}
            >
              <X size={20} />
            </button>
            <img src={photoUrl} alt="Check foto" />
          </div>
        </div>
      )}
    </>
  );
}

export default PhotoDetailModal;

import api from 'utils/axios';
import BffApiResponse from 'models/BffApiResponse';
import { formattingTitaskBffResponseErrors } from 'utils/axiosResponse';
import User from 'models/User';

const SignInService = {
  login: async (
    username: string,
    password: string,
    country: string
  ): Promise<BffApiResponse> => {
    try {
      const body = {
        email: username,
        password: password,
      };
      const response = await api.post(`auth/login/${country}`, body);
      const userData = response.data.data;
      let user: User | null = null;

      localStorage.setItem('token', userData.token);
      if (userData) {
        user = userData;
      }

      return new BffApiResponse(true, user);
    } catch (error: any) {
      return formattingTitaskBffResponseErrors(error);
    }
  },
};

export default SignInService;

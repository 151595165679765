import React, { useEffect, useMemo, useState } from 'react';
import { X, LogIn, LogOut, Building } from 'lucide-react';
import {
  GoogleMap,
  Marker,
  Circle,
  useLoadScript,
  InfoWindow,
} from '@react-google-maps/api';

interface MapGpsDetailModalProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  storeData: CheckMapData | null;
  checkInData: CheckMapData | null;
  checkOutData: CheckMapData | null;
  currentType: 'in' | 'out';
}

export interface CheckMapData {
  lat: number;
  lng: number;
  content: string;
  distance?: number;
}

const DISTANCE_THRESHOLD_IN_METERS = 100;
const INITIAL_ZOOM = 17;

function MapGpsDetailModal({
  isOpen,
  handleCloseModal,
  storeData,
  checkInData,
  checkOutData,
  currentType,
}: MapGpsDetailModalProps) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyALg0tjR1NXtOovVqjN048apiJ3DZkbJnc',
  });

  const [zoom, setZoom] = useState(INITIAL_ZOOM);
  const [selectedMarker, setSelectedMarker] = useState<
    'in' | 'out' | 'store' | null
  >(null);

  const center = useMemo(() => {
    if (currentType === 'in' && checkInData) {
      return { lat: checkInData.lat, lng: checkInData.lng };
    }
    if (currentType === 'out' && checkOutData) {
      return { lat: checkOutData.lat, lng: checkOutData.lng };
    }
    return storeData
      ? { lat: storeData.lat, lng: storeData.lng }
      : { lat: 0, lng: 0 };
  }, [currentType, checkInData, checkOutData, storeData]);

  useEffect(() => {
    if (isOpen) {
      setZoom(INITIAL_ZOOM);
      setSelectedMarker(currentType === 'in' ? 'in' : 'out');
    }
  }, [isOpen, currentType]);

  const handleMarkerClick = (marker: 'in' | 'out' | 'store') => {
    setSelectedMarker((prev) => (prev === marker ? null : marker));
  };

  if (!isLoaded) return <div>Cargando mapa...</div>;

  return (
    <>
      {isOpen && (
        <div className="attendance-overlay">
          <div className="attendance-map-modal">
            <button
              className="attendance-close-button"
              onClick={handleCloseModal}
            >
              <X size={20} />
            </button>

            <GoogleMap
              center={center}
              zoom={zoom}
              mapContainerStyle={{ width: '100%', height: '500px' }}
              onClick={() => setSelectedMarker(null)}
              options={{
                fullscreenControl: true,
                fullscreenControlOptions: {
                  position: google.maps.ControlPosition.BOTTOM_RIGHT,
                },
              }}
            >
              {/* 📍 Tienda */}
              {storeData && (
                <>
                  <Marker
                    position={storeData}
                    icon={{
                      url: 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png',
                    }}
                    onClick={() => handleMarkerClick('store')}
                  />
                  {selectedMarker === 'store' && (
                    <InfoWindow
                      position={{
                        lat: storeData.lat + 0.0003,
                        lng: storeData.lng,
                      }}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#8E2DD2',
                            fontWeight: '500',
                          }}
                        >
                          <Building size={16} />
                          <span
                            style={{
                              textDecoration: 'underline',
                              marginLeft: '5px',
                            }}
                          >
                            Tienda
                          </span>
                        </div>
                        <span>{storeData.content}</span>
                      </div>
                    </InfoWindow>
                  )}
                  <Circle
                    center={storeData}
                    radius={DISTANCE_THRESHOLD_IN_METERS}
                    options={{
                      strokeColor: '#0000FF',
                      strokeOpacity: 0.5,
                      strokeWeight: 2,
                      fillColor: '#0000FF',
                      fillOpacity: 0.2,
                    }}
                  />
                </>
              )}

              {/* ✅ Check-in y 🚫 Check-out: Se renderizan en orden según currentType */}
              {currentType === 'out' && checkInData && (
                <>
                  <Marker
                    position={checkInData}
                    icon={{
                      url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                    }}
                    onClick={() => handleMarkerClick('in')}
                  />
                  {selectedMarker === 'in' && (
                    <InfoWindow
                      position={{
                        lat: checkInData.lat + 0.0003,
                        lng: checkInData.lng,
                      }}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#2ECC71',
                            fontWeight: '500',
                          }}
                        >
                          <LogIn size={16} />
                          <span
                            style={{
                              textDecoration: 'underline',
                              marginLeft: '5px',
                            }}
                          >
                            Check in
                          </span>
                        </div>
                        <span>{checkInData.content}</span>
                        {checkInData.distance !== undefined && (
                          <span>
                            A{' '}
                            <b
                              style={{
                                fontWeight: 600,
                                textDecoration: 'underline',
                                color:
                                  checkInData.distance >
                                  DISTANCE_THRESHOLD_IN_METERS
                                    ? 'red'
                                    : undefined,
                              }}
                            >
                              {Math.round(checkInData.distance * 100) / 100}m
                            </b>{' '}
                            de la tienda.
                          </span>
                        )}
                      </div>
                    </InfoWindow>
                  )}
                </>
              )}

              {currentType === 'in' && checkOutData && (
                <>
                  <Marker
                    position={checkOutData}
                    icon={{
                      url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                    }}
                    onClick={() => handleMarkerClick('out')}
                  />
                  {selectedMarker === 'out' && (
                    <InfoWindow
                      position={{
                        lat: checkOutData.lat + 0.0003,
                        lng: checkOutData.lng,
                      }}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FF4A4A',
                            fontWeight: '500',
                          }}
                        >
                          <LogOut size={16} />
                          <span
                            style={{
                              textDecoration: 'underline',
                              marginLeft: '5px',
                            }}
                          >
                            Check out
                          </span>
                        </div>
                        <span>{checkOutData.content}</span>
                        {checkOutData.distance !== undefined && (
                          <span>
                            A{' '}
                            <b
                              style={{
                                fontWeight: 600,
                                textDecoration: 'underline',
                                color:
                                  checkOutData.distance >
                                  DISTANCE_THRESHOLD_IN_METERS
                                    ? 'red'
                                    : undefined,
                              }}
                            >
                              {Math.round(checkOutData.distance * 100) / 100}m
                            </b>{' '}
                            de la tienda.
                          </span>
                        )}
                      </div>
                    </InfoWindow>
                  )}
                </>
              )}

              {currentType === 'in' && checkInData && (
                <>
                  <Marker
                    position={checkInData}
                    icon={{
                      url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                    }}
                    onClick={() => handleMarkerClick('in')}
                  />
                  {selectedMarker === 'in' && (
                    <InfoWindow
                      position={{
                        lat: checkInData.lat + 0.0003,
                        lng: checkInData.lng,
                      }}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#2ECC71',
                            fontWeight: '500',
                          }}
                        >
                          <LogIn size={16} />
                          <span
                            style={{
                              textDecoration: 'underline',
                              marginLeft: '5px',
                            }}
                          >
                            Check in
                          </span>
                        </div>
                        <span>{checkInData.content}</span>
                        {checkInData.distance !== undefined && (
                          <span>
                            A{' '}
                            <b
                              style={{
                                fontWeight: 600,
                                textDecoration: 'underline',
                                color:
                                  checkInData.distance >
                                  DISTANCE_THRESHOLD_IN_METERS
                                    ? 'red'
                                    : undefined,
                              }}
                            >
                              {Math.round(checkInData.distance * 100) / 100}m
                            </b>{' '}
                            de la tienda.
                          </span>
                        )}
                      </div>
                    </InfoWindow>
                  )}
                </>
              )}

              {currentType === 'out' && checkOutData && (
                <>
                  <Marker
                    position={checkOutData}
                    icon={{
                      url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                    }}
                    onClick={() => handleMarkerClick('out')}
                  />
                  {selectedMarker === 'out' && (
                    <InfoWindow
                      position={{
                        lat: checkOutData.lat + 0.0003,
                        lng: checkOutData.lng,
                      }}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FF4A4A',
                            fontWeight: '500',
                          }}
                        >
                          <LogOut size={16} />
                          <span
                            style={{
                              textDecoration: 'underline',
                              marginLeft: '5px',
                            }}
                          >
                            Check out
                          </span>
                        </div>
                        <span>{checkOutData.content}</span>
                        {checkOutData.distance !== undefined && (
                          <span>
                            A{' '}
                            <b
                              style={{
                                fontWeight: 600,
                                textDecoration: 'underline',
                                color:
                                  checkOutData.distance >
                                  DISTANCE_THRESHOLD_IN_METERS
                                    ? 'red'
                                    : undefined,
                              }}
                            >
                              {Math.round(checkOutData.distance * 100) / 100}m
                            </b>{' '}
                            de la tienda.
                          </span>
                        )}
                      </div>
                    </InfoWindow>
                  )}
                </>
              )}
            </GoogleMap>
          </div>
        </div>
      )}
    </>
  );
}

export default MapGpsDetailModal;

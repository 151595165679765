import React from 'react';

const PAGE_DELAY = 500;

export const Settings = React.lazy(() =>
  Promise.all([
    import('../scenes/Settings/Settings'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const SettingsCreateCertification = React.lazy(() =>
  Promise.all([
    import('../scenes/Settings/Certifications/create/CreateCertification'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const CreateModule = React.lazy(() =>
  Promise.all([
    import('../scenes/Settings/Certifications/module/CreateModule'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const SettingsVertical = React.lazy(() =>
  Promise.all([
    import('../scenes/Settings/Vertical/Vertical'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const SettingsMaterials = React.lazy(() =>
  Promise.all([
    import('../scenes/Settings/Materials/Materials'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const SettingsPaymentSchedule = React.lazy(() =>
  Promise.all([
    import('../scenes/Settings/PaymentSchedule/PaymentSchedule'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const SettingsCertifications = React.lazy(() =>
  Promise.all([
    import('../scenes/Settings/Certifications/Certifications'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const SettingsStores = React.lazy(() =>
  Promise.all([
    import('../scenes/Settings/Stores/Stores'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const SettingsClients = React.lazy(() =>
  Promise.all([
    import('../scenes/Settings/Clients/Clients'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const Users = React.lazy(() =>
  Promise.all([
    import('../scenes/Users/Users'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const PaymentsSchedule = React.lazy(() =>
  Promise.all([
    import('../scenes/PaymentsSchedule/PaymentsSchedule'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const QrCode = React.lazy(() =>
  Promise.all([
    import('../scenes/QrCode/QrCode'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const Tasks = React.lazy(() =>
  Promise.all([
    import('../scenes/Tasks/Tasks'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const Taskers = React.lazy(() =>
  Promise.all([
    import('../scenes/Taskers/Taskers'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const TaskerSelected = React.lazy(() =>
  Promise.all([
    import('../scenes/Taskers/Actions/TaskerSelected'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const Home = React.lazy(() =>
  Promise.all([
    import('../scenes/Home/Home'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const Profile = React.lazy(() =>
  Promise.all([
    import('../scenes/Profile/Profile'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const SignIn = React.lazy(() =>
  Promise.all([
    import('../scenes/SignIn'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const RestorePassword = React.lazy(() =>
  Promise.all([
    import('../scenes/RestorePassword'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const RestorePasswordV2 = React.lazy(() =>
  Promise.all([
    import('../scenes/RestorePasswordV2'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const PageNotFound = React.lazy(() =>
  Promise.all([
    import('../scenes/PageNotFound'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const PageUnauthorized = React.lazy(() =>
  Promise.all([
    import('../scenes/PageUnauthorized'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const TestPage = React.lazy(() =>
  Promise.all([
    import('../scenes/TestPage'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

/** START KIT LAZY ROUTES */
export const KitHome = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Home/Home'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitPop = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Lifts/Pop/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitTaskers = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Taskers/Taskers'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitAttendances = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Attendances'),
    // import('../scenes/Kit/Attendances/index.bkp'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitLifts = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Lifts/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitSos = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Lifts/Sos/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitStock = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Lifts/Stock/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitSells = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Lifts/Sells/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitForms = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Lifts/Forms/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitOptionalForms = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Lifts/OptionalForms/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitCampaignsV2 = React.lazy(() =>
  Promise.all([
    import('scenes/Kit/Campaign/Campaign'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitViewTurnV2 = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/ViewTurn/ViewTurn'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitViewCampaignV2 = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/ViewCampaign/ViewCampaign'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitCreateCampaignV2 = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/CreateCampaign/CreateCampaign'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitCreateTurn = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/CreateTurn/CreateTurn'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitCreateMultipleTurn = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/CreateMultipleTurn/CreateMultipleTurn'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitSettings = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitBrands = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/Brands/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitBrandCreate = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/Brands/CreateBrand'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitClients = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/Clients/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitClientCreate = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/Clients/CreateClient'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitClientUpdate = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/Clients/UpdateClient'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitBusinessChains = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/BusinessChains/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitBusinessChainsCreate = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/BusinessChains/CreateBusinessChain'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitBusinessChainsUpdate = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/BusinessChains/UpdateBusinessChain'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitStores = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/Stores/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitCreateStore = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/CreateStore/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitViewStore = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/ViewStore/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitCategories = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/Categories/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitCategoryCreate = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/Categories/CreateCategory'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitSettingForms = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/Forms/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitSettingCreateForm = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/CreateForm/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitSettingViewForm = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/ViewForm/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitSettingViewOptionalForm = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/ViewOptionalForm/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitSettingOptionalForms = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/OptionalForms/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitSettingCreateOptionalForm = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/CreateOptionalForm/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitImporters = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Importers/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitImportersShiftAndUserBulk = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Importers/ShiftAndUserBulk/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitImportersStores = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Importers/Stores/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const GoAnnouncementsView = React.lazy(() =>
  Promise.all([
    import('../scenes/Go/Announcements/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const GoAssignedUsersView = React.lazy(() =>
  Promise.all([
    import('../scenes/Go/AssignedUsers/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const GoAssignedUsersViewDetail = React.lazy(() =>
  Promise.all([
    import('../scenes/Go/AssignedUsers/components/ViewDetailUser'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const GoApplicationsView = React.lazy(() =>
  Promise.all([
    import('../scenes/Go/Applications/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const GoSettingView = React.lazy(() =>
  Promise.all([
    import('../scenes/Go/Settings/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const GoSettingVerticalsView = React.lazy(() =>
  Promise.all([
    import('../scenes/Go/Settings/verticals/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const GoSettingCompaniesView = React.lazy(() =>
  Promise.all([
    import('../scenes/Go/Settings/companies/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const GoSettingJobTypesView = React.lazy(() =>
  Promise.all([
    import('../scenes/Go/Settings/jobTypes/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const GoSettingMedicsView = React.lazy(() =>
  Promise.all([
    import('../scenes/Go/Settings/medics/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const GoSettingDocumentsView = React.lazy(() =>
  Promise.all([
    import('../scenes/Go/Settings/documents/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const CollectorUserTurnView = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/CollectorUserTurn/CollectorUserTurn'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitConfirmTurnView = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/ConfirmTurn/ConfirmTurn'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitSalesOutlet = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/SalesOutlets/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitModernSalesOutlet = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/SalesOutlets/Modern/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);

export const KitTraditionalSalesOutlet = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/SalesOutlets/Traditional/index'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitCreateModernSalesOutlet = React.lazy(() =>
  Promise.all([
    import(
      '../scenes/Kit/Settings/SalesOutlets/Modern/CreateModernSalesOutlet'
    ),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitCreateTraditionalSalesOutlet = React.lazy(() =>
  Promise.all([
    import(
      '../scenes/Kit/Settings/SalesOutlets/Traditional/CreateTraditionalSalesOutlet'
    ),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
export const KitDetailModernSalesOutlet = React.lazy(() =>
  Promise.all([
    import('../scenes/Kit/Settings/SalesOutlets/UpdateModernSalesOutlet'),
    new Promise((resolve) => setTimeout(resolve, PAGE_DELAY)),
  ]).then(([module]) => {
    return module;
  })
);
/** END KIT LAZY ROUTES */

import React from 'react';
import Text from '../Text/Text';
import Country from '../../../models/Country';

import './CountrySelector.scss';

interface CountrySelectorProps {
  secondaryColor?: boolean;
  selectedCountry: Country;
  setSelectedCountry: (country: Country) => any;
  countries: Country[];
}

const CountrySelector = ({
  secondaryColor = false,
  selectedCountry,
  setSelectedCountry,
  countries,
}: CountrySelectorProps) => {
  const countryOnClick = async (country: Country) => {
    setSelectedCountry(country);
  };

  return (
    <div
      className={`countries_container${
        secondaryColor ? ' countries_container--secondary' : ''
      }`}
    >
      <Text text={<b>Elige tu país de operación</b>} />
      <div
        className={`countries__content${
          secondaryColor ? ' countries__content--secondary' : ''
        }`}
      >
        {countries.map((country: Country) => (
          <div
            className={`country__item${
              selectedCountry?.code === country.code
                ? ' country__item--selected'
                : ''
            }`}
            onClick={() => countryOnClick(country)}
            key={country.code}
            title={country.name}
          >
            <img
              className="country__item__img"
              src={country.flag}
              alt={country.name}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountrySelector;
